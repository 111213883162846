import { FlipIcon, Icon } from "../../text/icon/icon"
import React, { useState } from "react"
import { TableProps } from "../Table"

type RowProps<T> = {
	data: TableProps<T>["data"]
	headerParam: TableProps<T>["headerParam"]
	header: TableProps<T>["header"]
}

const Row = <T, >(props: RowProps<T>) => {
	const [clickedRow, setClickedRow] = useState<T | null>(null)
	const hasBottom = props.headerParam && props.headerParam.paging
	return <>
		{props.data.map((row, rowIndex) => (<div key={rowIndex}>
			<div
				className={`grid grid-cols-${props.headerParam ? Object.keys(props.header).length + 1 : Object.keys(props.header).length} items-center bg-white pr-7 pl-7 py-3 border border-c-stone200 ${props.data.length === rowIndex + 1 && !hasBottom ? "rounded-b-2xl" : ""}`}>
				{(Object.keys(props.header) as Array<keyof T>).map((col: keyof T, colIndex) => {
					const className = colIndex === 0 ? "text-start" : "text-center"
					return <div key={col as string} className={className}>
						{row[col]}
					</div>
				})}
				{props.headerParam && <div className="flex justify-end" onClick={() => {
					if(row === clickedRow) {
						setClickedRow(null)
					} else {
						setClickedRow(row as T)
						if(props.headerParam!.rowAction.onclick) {
							props.headerParam!.rowAction.onclick(row as T)
						}
					}
				}}>
					<Icon type="dropdown-arrow" size="xtr-small" flip={row === clickedRow ? FlipIcon.vertical : undefined}/>
				</div>}
			</div>
			{props.headerParam && props.headerParam.rowAction.display && props.headerParam.rowAction.display(row as T, row === clickedRow, rowIndex)}
		</div>))}
	</>
}

export default Row
