import React, { useContext, useEffect, useState } from "react"
import { authContext } from "../../service/api/authContext"
import Table, { FilterStatusEnum } from "../../components/table/Table"
import { useNavigate } from "react-router-dom"
import Loader from "../../components/Modal/Loading"
import AssignmentCard from "./Content/AssignmentCard"
import ValidInvoiceCard from "./Content/ValidInvoiceCard"

type OrderData = {
	"number": string
	"start": string
	"end": string
	"shipper": string
	"status": string
	"statusId": number
	"readyForInvoice": boolean
	"carrier": string
	"missions": {
		"id": string
		"start": string
		"end": string
		"vehicule": string
		"code": string
	}[]
}
type OrderResponse = {
	success: boolean,
	orders: OrderData[]
}

const Show = () => {
	const navigate = useNavigate()
	const { token } = useContext(authContext)
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState<boolean>(false)
	const [openValidInvoice, setOpenValidInvoice] = useState<boolean>(false)
	const [changeAssignOrderNumber, setChangeAssignOrderNumber ] = useState<{ order: string, mission: string }| null>(null)
	const [ orderData, setOrderData ] = useState<OrderResponse>()
	const [filter, setFilterStatus] = useState({
		carrier: FilterStatusEnum.INACTIVE,
		number: FilterStatusEnum.INACTIVE,
		type: FilterStatusEnum.INACTIVE,
		category: FilterStatusEnum.INACTIVE,
		shipper: FilterStatusEnum.INACTIVE,
		start: FilterStatusEnum.INACTIVE,
		end: FilterStatusEnum.INACTIVE
	})

	const tableHeader = {
		number: {
			label: "N°",
			filter: {
				status: filter.number,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					number: s
				}))
			}
		},
		type: {
			label: "Type",
			filter: {
				status: filter.type,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					type: s
				}))
			}
		},
		category: {
			label: "Catégorie",
			status: filter.category,
			setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
				...prevState,
				category: s
			}))
		},
		status: {
			label: "Status"
			// todo rules for conditional color  ?
		},
		start: {
			label: "Date",
			filter: {
				status: filter.start,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					date: s
				}))
			}
		},
		shipper: {
			label: "Expéditeur",
			filter: {
				status: filter.shipper,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					shipper: s
				}))
			}
		},
		sAmount: {
			label: "HT (E)"
		},
		carrier: {
			label: "Transporteur",
			filter: {
				status: filter.carrier,
				setStatus: (s: FilterStatusEnum) => setFilterStatus((prevState) => ({
					...prevState,
					carrier: s
				}))
			}
		},
		cAmount: {
			label: "HT (T)"
		},
		mission: {
			hidden: true,
			label: "Mission",
			subElem: {
				id: { hidden: true, label: "N°mission" },
				start: { label: "Du" },
				end: { label: "Au" },
				vehicule: { label: "véhicule" },
				code: { label: "Code chauffeur" }
			}
		}
	}

	const handleCard = (orderNumber: string, missionId: string, assignment = true) => {
		setChangeAssignOrderNumber({ order: orderNumber, mission: missionId })
		if(assignment) {
			setOpen(true)
		} else {
			setOpenValidInvoice(true)
		}
	}

	const onSuccessAssign = (newCompanyName: string) => {
		if(!orderData || !changeAssignOrderNumber) {
			return
		}
		Object.values(orderData).forEach((orders) => {
			if(typeof orders !== "boolean") {
				for(const order of orders) {
					if(order.number === changeAssignOrderNumber.order) {
						order.carrier = newCompanyName
						order.statusId = 5000
						order.status = "En attente d'exécution"
						break
					}
				}
			}
		})
	}

	const onSuccessValidation = () => {
		if(!orderData || !changeAssignOrderNumber) {
			return
		}
		Object.values(orderData).forEach((orders) => {
			if(typeof orders !== "boolean") {
				for(const order of orders) {
					if(order.number === changeAssignOrderNumber.order) {
						order.readyForInvoice = true
						break
					}
				}
			}
		})
		setOpenValidInvoice(false)
	}

	const headerParam = {
		search: {
			fullText: true
		},
		extract: true,
		paging: {
			nbLine: 10
		},
		rowAction: {
			display: (row: OrderData, isClicked: boolean, rowIndex: number) => {
				if(isClicked) {
					return <div key={rowIndex} className="border border-c-stone200">
						<div className="grid grid-cols-7 pr-7 pl-7 py-3">
							{Object.entries(tableHeader.mission.subElem).map(([key, value]) => {
								if("hidden" in value && value.hidden) {
									return <></>
								}
								return 	<div key={key}>
									{"label" in value ? value.label : ""}
								</div>
							})}
						</div>
						{row.missions.map((mission, index) => <>
							<div key={index} className="flex w-full"><div className="w-4/5 h-1 border-t border-c-stone200"/></div>
							<div className="grid grid-cols-7 pr-7 pl-7 py-3">
								<div>{mission.start}</div>
								<div>{mission.end}</div>
								<div>{mission.vehicule}</div>
								<div>{mission.code}</div>
								{row.statusId !== 3000 && <div
									onClick={() => {
										navigate(`/mission/${mission.id}/report`)
									}}
									className="bg-yellow400 cursor-pointer flex justify-center align-middle rounded-2xl px-1 py-1"
								>
									Détail
								</div>}
								{(row.statusId === 7000 || row.statusId === 7001) && !row.readyForInvoice && <div>
									<span
										className="bg-yellow400 cursor-pointer flex justify-center align-middle rounded-2xl px-1 py-1 ml-5"
										onClick={() => {
											handleCard(row.number, mission.id, false)
										}}
									>
										Valider pour la facturation
									</span>
								</div>}
								{row.statusId === 3000 && <div
									onClick={() => {
										handleCard(row.number, mission.id)
									}}
									className="bg-yellow400 cursor-pointer flex justify-center align-middle rounded-2xl px-1 py-1"
								>
									Affecter un transporteur
								</div>}
							</div>
						</>)}
					</div>
				}
				return <></>
			}
		}
	}

	useEffect(() => {
		setLoading(true)
		const fetchData = async() => {
			try {
				const path = `${process.env.REACT_APP_API_DOMAIN}/api/orders`
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				if(!response.ok) {
					navigate("/")
				}
				const orderResponse: OrderResponse = await response.json()
				if(orderResponse && orderResponse.success) {
					setOrderData(orderResponse)
				}
				setLoading(false)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
				setLoading(false)
			}
		}
		fetchData()
	}, [])

	return <div className="flex flex-col min-h-full px-20 py-10 bg-backGround">
		<span className="self-center text-5xl bold pb-9">Mes commandes</span>
		<AssignmentCard open={open} data={changeAssignOrderNumber} onClose={() => setOpen(false)} onSuccess={onSuccessAssign}/>
		<ValidInvoiceCard open={openValidInvoice} data={changeAssignOrderNumber} onClose={() => setOpenValidInvoice(false)} onSuccess={onSuccessValidation} />
		<Loader open={loading}/>
		<div className="rounded-3xl bg-white w-full p-7">
			{orderData?.orders && <Table<OrderData> data={orderData.orders} header={tableHeader} headerParam={headerParam}/>}
		</div>
	</div>
}

export default Show
