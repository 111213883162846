import React, { useContext, useEffect, useState } from "react"

import { ReportOrder, ReportRealization } from "./content/components"
import { Underline } from "../../../../components/text/underline/underline"
import { DataRepportApi } from "./type"
import { useNavigate, useParams } from "react-router-dom"
import { ReportVehicle } from "./content/vehicle"
import { ReportDetail } from "./content/detail"
import HereMap from "../../../../components/hereMap/HereMap"
import { Button } from "../../../../components/button/Button"
import { authContext } from "../../../../service/api/authContext"
import truck2youLogo from "../../../../assets/commom/truckyou_logo.png"
import { STEP_COLOR, STEP_TYPE } from "../../../../helper/here"

const Report = () => {
	const navigate = useNavigate()
	const { token, isAdmin } = useContext(authContext)
	const { id } = useParams()
	const [repportData, setRepportData] = useState<DataRepportApi>()
	const [ resetMap, setResetMap ] = useState<boolean>(false)
	const [carrierDisplay, setCarrierDisplay] = React.useState(false)

	const handleChange = () => {
		setCarrierDisplay(!carrierDisplay)
	}

	const handlePrintReport = () => {
		setResetMap(true)
		setTimeout(() => {
			window.print()
		}, 500)
	}

	useEffect(() => {
		const fetchData = async() => {
			try {
				let path = `${process.env.REACT_APP_API_DOMAIN}/api/mission/${id}/report`
				if(isAdmin) {
					path += `?display=${carrierDisplay ? "shipper" : "carrier"}`
				}
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				if(!response.ok) {
					navigate("/")
				}
				const data: DataRepportApi = await response.json()
				data.command.date = new Date(data.command.date)
				setRepportData(data)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
			}
		}
		fetchData()
	}, [id, carrierDisplay])

	useEffect(() => {
		// Fonction à exécuter toutes les deux minutes
		const fetchData = async() => {
			if(!repportData?.mission?.lastOperation) {
				const path = `${process.env.REACT_APP_API_DOMAIN}/api/mission/${id}/report/lastPoint`
				const response = await fetch(path, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				})
				if(response.ok) {
					const data: { succes: boolean, mapPoint: DataRepportApi["mapPoints"][0] } = await response.json()
					if(data) {
						setRepportData((prev) => {
							if(!prev) {
								return prev
							}
							const updatedMapPoints = [
								...prev.mapPoints.map((point) => (point.type === STEP_TYPE.CAR
									? { ...point, type: -100 }
									: point)),
								data.mapPoint
							]

							return {
								...prev,
								mapPoints: updatedMapPoints
							}
						})
					}
				} else {
					clearInterval(intervalId)
				}
			}
		}

		// Démarrer l'intervalle
		const intervalId = setInterval(fetchData, 120000) // 2 minutes

		// Nettoyage de l'intervalle à la destruction du composant
		return () => clearInterval(intervalId)
	}, [repportData])

	return <div id="main" style={{ marginLeft: 20, backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
		<div className="row space-around m-media-t">
			<div className="row h-center col-media-3 col-s-none col-sm-none col-none">
				<img src={truck2youLogo} alt="logo truck2you"/>
			</div>
		</div>
		<div className="flex mb-10 m-5 justify-between">
			<Underline><span className="xtrlarge bold">Rapport de mission</span></Underline>
			{isAdmin && <div className="display-media-none">
				<input checked={carrierDisplay} onChange={handleChange} type="checkbox"/><span>Affichage {carrierDisplay ? "Expéditeur" : "Transporteur"}</span>
			</div>}
			{repportData?.mission?.lastOperation && <div className="m-t m-r">
				<Button text="Télécharger le rapport de mission" onClick={handlePrintReport} className="col-media-none"/>
			</div>}
		</div>
		{repportData && repportData.command && <div className="row space-around mb-6 print:mb-96">
			<div className="col-6 space-between col-media-11">
				<div className="row space-between m-b">
					<div className="row col-5 col-media-5">
						<ReportOrder command={repportData!.command}/>
					</div>
					{repportData.mission && <div className="row col-6 col-media-6">
						<ReportRealization mission={repportData!.mission}/>
					</div>}
				</div>
				<div className="row">
					<div className="row col-12 col-media-12">
						{repportData.vehicle && <ReportVehicle vehicle={repportData.vehicle}/>}
					</div>
				</div>
			</div>
			<div className="row col-5 space-around print:basis-11/12 print:p-2.5">
				<div className="flex" style={{ minWidth: 779, maxWidth: 779, maxHeight: 443, minHeight: 443 }}>
					{repportData.mapPoints && repportData.mapPoints.length > 0 && <HereMap reset={{ val: resetMap, askReset: setResetMap }} positions={repportData.mapPoints}/>}
				</div>
				<div className="flex mt-2 items-center space-between">
					<div className="flex items-center pr-5">
						<div>
							<svg fill="#000000" width={45} viewBox="0 0 512 512" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
								<g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
								<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<g id="Layer_2">
										<g>
											<path d="M401.8,206.5c-1.4-2-3.7-3.2-6.1-3.2h-46.5v-19.8c0-4.1-3.4-7.5-7.5-7.5H173.2c-4.1,0-7.5,3.4-7.5,7.5v121.2 c0,4.1,3.4,7.5,7.5,7.5h21.2c3.9,13.6,16.5,23.7,31.3,23.7s27.4-10,31.3-23.7h83.1c3.9,13.6,16.5,23.7,31.3,23.7 s27.4-10,31.3-23.7h15.5c4.1,0,7.5-3.4,7.5-7.5V243c0-1.6-0.5-3.1-1.4-4.3L401.8,206.5z M410.8,245.2h-33.6v-26.8h14.7 L410.8,245.2z M180.7,191.1h153.5v106.2h-76.5c-2.9-15.1-16.1-26.5-32-26.5s-29.2,11.4-32,26.5h-13V191.1z M225.7,320.9 c-9.7,0-17.6-7.9-17.6-17.6s7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6S235.4,320.9,225.7,320.9z M371.5,320.9 c-9.7,0-17.6-7.9-17.6-17.6s7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6S381.2,320.9,371.5,320.9z M403.5,297.3 c-2.9-15.1-16.1-26.5-32-26.5c-8.6,0-16.5,3.4-22.3,8.9v-61.3h12.9v34.3c0,4.1,3.4,7.5,7.5,7.5h41.3v37.1H403.5z"></path> <path d="M93.6,191.1h53.5c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H93.6c-4.1,0-7.5,3.4-7.5,7.5S89.5,191.1,93.6,191.1z"></path> <path d="M147.1,203.4h-41.2c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h41.2c4.1,0,7.5-3.4,7.5-7.5S151.3,203.4,147.1,203.4z"></path> <path d="M147.1,230.7h-26c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h26c4.1,0,7.5-3.4,7.5-7.5S151.3,230.7,147.1,230.7z"></path> <path d="M147.1,258h-9c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h9c4.1,0,7.5-3.4,7.5-7.5S151.3,258,147.1,258z"></path>
										</g>
									</g>
								</g>
							</svg>
						</div>
						<span>Position du Véhicule</span>
					</div>
					<div className="flex items-center pr-5">
						<div className="pr-2">
							<svg height="24" xmlns="http://www.w3.org/2000/svg" width={24.5}>
								<ellipse stroke="grey" fill="white" cx="50%" cy="55%" rx="8pt" ry="10"/>
								<text x="50%" y="55%" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold", textAnchor: "middle", alignmentBaseline: "middle", fill: STEP_COLOR.WAITING }}>x</text>
							</svg>
						</div>
						<span>Étape en attente</span>
					</div>
					<div className="flex items-center pr-5">
						<div className="pr-2">
							<svg height="24" xmlns="http://www.w3.org/2000/svg" width={24.5}>
								<ellipse stroke="grey" fill="white" cx="50%" cy="55%" rx="8pt" ry="10"/>
								<text x="50%" y="55%" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold", textAnchor: "middle", alignmentBaseline: "middle", fill: STEP_COLOR.STARTED }}>x</text>
							</svg>
						</div>
						<span>Étape en cours</span>
					</div>
					<div className="flex items-center pr-5">
						<div className="pr-2">
							<svg height="24" xmlns="http://www.w3.org/2000/svg" width={24.5}>
								<ellipse stroke="grey" fill="white" cx="50%" cy="55%" rx="8pt" ry="10"/>
								<text x="50%" y="55%" style={{ fontFamily: "Arial, sans-serif", fontWeight: "bold", textAnchor: "middle", alignmentBaseline: "middle", fill: STEP_COLOR.FINISHED }}>x</text>
							</svg>
						</div>
						<span>Étape en Fini</span>
					</div>
				</div>
			</div>
		</div>}
		<br/>
		{!repportData?.vehicle && <><br/><br/></>}
		{repportData?.missionDetail && <div className="row space-around m-l">
			<ReportDetail detail={repportData.missionDetail}/>
		</div>}
	</div>
}
export default Report
