import * as XLSX from "xlsx"

export const generateXLSX = (data: any[][], title: string, header?: string[]): void => {
	// Si un header est fourni, on l'ajoute en début des données
	const worksheetData = header ? [header, ...data] : data

	// Créer une feuille de calcul à partir des données
	const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

	// Créer un classeur (workbook) et y ajouter la feuille de calcul
	const workbook = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

	// Convertir le classeur en un fichier binaire
	const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

	// Créer un Blob pour le téléchargement
	const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })

	// Créer un lien pour télécharger le fichier
	const a = document.createElement("a")
	a.href = URL.createObjectURL(blob)
	a.download = `${title}.xlsx` // Nom du fichier
	a.style.display = "none"
	document.body.appendChild(a)

	// Déclencher le téléchargement
	a.click()

	// Nettoyer
	document.body.removeChild(a)
}