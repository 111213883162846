import React from "react"
import { FilterStatusEnum, TableProps } from "../Table"
import { FlipIcon, Icon } from "../../text/icon/icon"

type HeaderProps<T> = {
	headerParam: TableProps<T>["headerParam"]
	header: TableProps<T>["header"]
	setCurrentPage: (page: number) => void
}

const Header = (props: HeaderProps<any>) => {
	const changeStatus = (currentStatus: FilterStatusEnum): FilterStatusEnum => {
		switch(currentStatus) {
			case FilterStatusEnum.INACTIVE:
				return FilterStatusEnum.ASCENDING
			case FilterStatusEnum.ASCENDING:
				return FilterStatusEnum.DESCENDING
			case FilterStatusEnum.DESCENDING:
				return FilterStatusEnum.INACTIVE
			default:
				return FilterStatusEnum.INACTIVE
		}
	}

	return <div
		className={`grid grid-cols-${props.headerParam ? Object.keys(props.header).length : Object.keys(props.header).length} items-center pr-7 pl-7 py-3 bg-blue text-c-yellow400 rounded-t-xl h-20`}>
		{/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
		{Object.entries(props.header).map(([_, column], index) => {
			if(!column || column.hidden) {
				return null
			}

			if(column.filter) {
				return (
					<div
						key={index}
						className={`flex flex-row ${
							index === 0 ? "justify-start" : "justify-center"
						} cursor-pointer text-lg font-bold`}
						onClick={() => {
							props.setCurrentPage(0)
							column.filter!.setStatus(changeStatus(column.filter!.status))
						}}
					>
						{column.label}
						<span className="pl-1 self-center">
							{displaySort(column.filter!.status)}
						</span>
					</div>
				)
			}
			return (
				<div
					key={index}
					className={`flex flex-row ${
						index === 0 ? "justify-start" : "justify-center"
					} text-lg font-bold`}
				>
					{column.label}
				</div>
			)
		})}
		{props.headerParam && <div className="flex flex-row justify-end cursor-pointer text-lg font-bold">{props.headerParam.rowAction.title}</div>}
	</div>
}

const displaySort = (status: FilterStatusEnum) => {
	switch(status) {
		case FilterStatusEnum.ASCENDING:
			return <Icon type="sort-arrow" size="xtr-small"/>
		case FilterStatusEnum.DESCENDING:
			return <Icon type="sort-arrow" size="xtr-small" flip={FlipIcon.vertical}/>
		default:
			return <span className="mx-1 text-c-blue">.</span>
	}
}

export default Header
