import React, { useContext, useEffect, useState } from "react"
import { Modal as ModalReact } from "react-responsive-modal"
import Select from "../../../components/input/select/Select"
import { Button } from "../../../components/button/Button"
import { authContext } from "../../../service/api/authContext"
import Loader from "../../../components/Modal/Loading"
import CheckBox from "../../../components/input/checkbox/CheckBox"
import { Text } from "../../../components/text/Text"

type AssignmentCardProps = {
	onClose?: () => void
	onSuccess?: (companyName: string) => void
	open: boolean
	data: { order: string, mission: string } | null
}

type TransporterData = {
	[companyId: string]: {
		name: string,
		vehicles: { typology: string, plate: string }[]
	}
}
type TransporterResponse = {
	success: boolean,
	data: TransporterData
}

const AssignmentCard = ({ data, open, onClose = () => undefined, onSuccess = () => undefined }: AssignmentCardProps) => {
	const [selectedTransporter, setSelectedTransporter] = useState<string[]>([])
	const [selectedTruck, setSelectedTruck] = useState<string[]>([])
	const [ ignoreFilter, setIgnoreFilter ] = useState<boolean>(false)
	const [ apiError, setApiError ] = useState<boolean>()
	const [loading, setLoading] = useState(false)
	const { token, isAdmin } = useContext(authContext)
	const [ transporterData, setTransporterData ] = useState<TransporterData>({})

	const handleCloseCard = () => {
		setSelectedTransporter([])
		setSelectedTruck([])
		setApiError(false)
	}

	const handleConfirmChange = async() => {
		setLoading(true)
		const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/order/${data?.order}/mission/${data?.mission}/assign`, {
			method: "PATCH",
			headers: {
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify({
				truck: selectedTruck[0]
			})
		})
		const result = await response.json()
		if(result.success) {
			onSuccess(getCompanyNameById()[0])
			handleCloseCard()
		} else {
			setApiError(true)
		}
		setLoading(false)
	}

	useEffect(() => {
		getCompaniesName()
		if(!open || !isAdmin) {
			handleCloseCard()
			return
		}
		setLoading(true)
		const fetchData = async() => {
			try {
				const queryParams = new URLSearchParams({
					ignoreFilter: ignoreFilter.toString() // Convertit en string pour être sûr que c'est bien formaté
				})

				const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/find/order/${data?.order}/mission/${data?.mission}/transporters?${queryParams}`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`
					}
				})

				const transporterResponse: TransporterResponse = await response.json()
				if(transporterResponse && transporterResponse.success) {
					setTransporterData(transporterResponse.data)
				}
				setLoading(false)
			} catch (error) {
				console.error("Erreur lors de la récupération des données de l'API", error)
				setLoading(false)
			}
		}
		fetchData()
	}, [ open, ignoreFilter ])

	const getCompanyNameById = () => (selectedTransporter[0] ? [ transporterData[selectedTransporter[0]]?.name ] : [])
	const getCompanyIdByName = (companyName: string) => {
		for(const companyId in transporterData) {
			if(transporterData[companyId].name === companyName) {
				return companyId
			}
		}
		return ""
	}
	const getCompaniesName = () => Object.values(transporterData).map((value) => value.name)

	const getVehiclesName = () => transporterData[selectedTransporter[0]].vehicles.map((value) => `${value.typology} ${value.plate}`)
	const getVehicleReference = (value: string) => {
		for(const vehicle of transporterData[selectedTransporter[0]].vehicles) {
			if(`${vehicle.typology} ${vehicle.plate}` === value) {
				return vehicle.plate
			}
		}
		return ""
	}
	const getVehicleName = () => transporterData[selectedTransporter[0]].vehicles.reduce((ref, currentValue) => {
		if(selectedTruck[0] === currentValue.plate) {
			return `${currentValue.typology} ${currentValue.plate}`
		}
		return ref
	}, "")

	return <ModalReact
		styles={{ modal: { overflow: "visible" }}}
		classNames={{ modal: "radius-common w-full h-fit" }}
		showCloseIcon={false}
		open={open}
		onClose={onClose}
		center
	>
		<Loader open={loading}/>
		<div className="flex flex-col space-between h-full">
			<div className="flex justify-center text-2xl bold pb-5">Affectation commande {data?.order}-{data?.mission}</div>
			<div className="flex flex-col h-2/6 space-around">
				<div className="p-2">Ignorer les filtres <CheckBox setChecked={(value) => {
					setIgnoreFilter(value)
					setSelectedTransporter([])
					setSelectedTruck([])
				}} checked={ignoreFilter}/></div>
				<div className="flex justify-center">
					<span className="w-2/6 text-1xl self-center">Choix du transporteur :</span>
					<Select selectedValue={getCompanyNameById()} setValue={(value) => {
						setSelectedTransporter([ getCompanyIdByName(value) ])
						setSelectedTruck([])
					}} values={getCompaniesName()} canBeNull={false}/>
				</div>
				{selectedTransporter.length > 0 && <div className="flex">
					<span className="w-2/6 text-1xl self-center">Choix du véhicule :</span>
					<Select selectedValue={[getVehicleName()]} setValue={(value) => setSelectedTruck([getVehicleReference(value)])} values={getVehiclesName()} canBeNull={false}/>
				</div>}
			</div>
			<div className="flex flex-col justify-center mt-10">
				{apiError && <Text className="flex justify-center text-c-warning pl-5 pb-2">La modification n&apos;a pas été prise en compte veuillez réessayer plus tard</Text>}
				<div className="flex justify-center space-around mt-10">
					{selectedTruck.length > 0 && <div className="w-3/12">
						<Button type="primary" text="Valider" onClick={handleConfirmChange}/>
					</div>}
					<div className="w-3/12">
						<Button type="secondary" text="Annuler" onClick={handleCloseCard}/>
					</div>
				</div>
			</div>
		</div>
	</ModalReact>
}
export default AssignmentCard
