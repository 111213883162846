import { FlatElem } from "./csv"

export type BrutElem = { [key: string]: string | BrutElem }[]

export const flattenBrutElem = (brutElem: BrutElem, headerInfo?: string[], headerOrder?: string[]): FlatElem => {
	console.log(headerOrder)
	if(headerInfo) {
		return [headerInfo, ...flattenData(brutElem, headerOrder)]
	}
	return [...flattenHeaderBrutElem(brutElem), ...flattenData(brutElem)]
}

const mergeArrays = (A: (string | undefined)[], B: (string | undefined)[]): string[] => {
	const maxLength = Math.max(A.length, B.length)

	return Array.from({ length: maxLength }, (_, index) => {
		const valueFromB = B[index] ?? undefined
		const valueFromA = A[index] ?? undefined

		// Priorité à B s'il a une valeur définie
		if(valueFromB !== undefined) {
			return valueFromB
		}

		// Sinon on prend la valeur de A si définie, sinon ""
		return valueFromA !== undefined ? valueFromA : ""
	})
}

export const flattenData = (brutElem: BrutElem, orderElem?: string[], prefix?: string): FlatElem => {
	const data: FlatElem = [] // Initialisation du tableau final

	if(orderElem) {
		brutElem.forEach((currentValue) => {
			let row: string[] = []
			Object.keys(currentValue).forEach((key) => {
				const value = currentValue[key]
				if(!orderElem || orderElem.includes((prefix ?? "") + key)) {
					const pos = orderElem.indexOf((prefix ?? "") + key)
					if(typeof value === "object" && Array.isArray(value)) {
						const flattenedObject = flattenData(value as BrutElem, orderElem, key.slice(0, 1))
						if(flattenedObject.length > 0) {
							row = mergeArrays(row, flattenedObject[0]) // Ajout des données aplaties
						} else {
							row[pos] = ""
						}
					} else {
						row[pos] = (value as string) ?? ""
					}
				} else if(typeof value === "object") {
					if(Array.isArray(value)) {
						const flattenedObject = flattenData(value as BrutElem, orderElem, key.slice(0, 1))
						if(flattenedObject.length > 0) {
							row = mergeArrays(row, flattenedObject[0]) // Ajout des données aplaties
						}
					}
				}
			})
			data.push(row)
		})
	} else {
		brutElem.forEach((currentValue) => {
			const row: string[] = []
			Object.keys(currentValue).forEach((key) => {
				const value = currentValue[key]
				if(value === null || value === undefined) {
					row.push("")
				} else if(typeof value === "object") {
					if(Array.isArray(value)) {
						const flattenedObject = flattenData(value as BrutElem)
						if(flattenedObject.length > 0) {
							row.push(...flattenedObject[0]) // Ajout des données aplaties
						} else {
							row.push("")
						}
					}
				} else {
					row.push(value as string)
				}
			})
			data.push(row)
		})
	}
	return data
}

export const flattenHeaderBrutElem = (brutElem: BrutElem, parent = ""): FlatElem => {
	const headerFlat: FlatElem = []
	const rowFlat: string[] = []

	Object.keys(brutElem[0]).forEach((key: string) => {
		const Elemkey = key as keyof BrutElem[0]

		if(typeof brutElem[0][Elemkey] === "object") {
			const test = flattenHeaderBrutElem(brutElem[0][Elemkey] as BrutElem, key)
			const result = test.reduce((accumulator, currentValue) => {
				accumulator.push(...currentValue) // Modify the accumulator
				return accumulator // Return the updated accumulator
			}, [])
			rowFlat.push(...result)
		} else if(parent === "") {
			rowFlat.push(key)
		} else {
			rowFlat.push(`${parent}.${key}`)
		}
	})
	headerFlat.push(rowFlat)
	return headerFlat
}
