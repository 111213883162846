import React, { useContext, useState } from "react"
import { Modal as ModalReact } from "react-responsive-modal"
import { Button } from "../../../components/button/Button"
import Loader from "../../../components/Modal/Loading"
import { authContext } from "../../../service/api/authContext"
import { Text } from "../../../components/text/Text"

type ValidInvoiceCardProps = {
	onClose?: () => void
	onSuccess?: () => void
	open: boolean
	data: { order: string, mission: string } | null
}

const ValidInvoiceCard = ({ data, open, onClose = () => undefined, onSuccess = () => undefined }: ValidInvoiceCardProps) => {
	const [loading, setLoading] = useState(false)
	const [apiError, setApiError] = useState(false)
	const { token } = useContext(authContext)

	const handleClose = () => {
		setApiError(false)
		onClose()
	}

	const handleValidForInvoice = async() => {
		setLoading(true)
		try {
			const path = `${process.env.REACT_APP_API_DOMAIN}/api/order/${data?.order}/invoice_ready`
			const response = await fetch(path, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
			if(response.ok) {
				onSuccess()
				setApiError(false)
			} else {
				setApiError(true)
			}
			setLoading(false)
		} catch (error) {
			console.log("Erreur durant l'exécution de la tâche")
			setLoading(false)
			setApiError(true)
		}
	}


	return <ModalReact
		styles={{ modal: { overflow: "visible" }}}
		classNames={{ modal: "radius-common w-full h-fit" }}
		showCloseIcon={false}
		open={open}
		onClose={handleClose}
		center
	>
		<Loader open={loading}/>
		<div>
			<div className="flex flex-col space-between h-full">
				<div className="flex justify-center text-2xl bold pb-5">Confirmation requise</div>
				<div className="flex justify-center text-xl pb-5">Validation de la commande <span className="bold text-xl px-1">{data?.order}-{data?.mission}</span> pour facturation</div>
			</div>
			<div className="flex flex-col justify-center mt-10">
				{apiError && <Text className="flex justify-center text-c-warning pl-5 pb-2">La modification n&apos;a pas été prise en compte veuillez réessayer plus tard</Text>}

				<div className="flex justify-center space-around">
					<div className="w-3/12">
						<Button type="primary" text="Valider" onClick={handleValidForInvoice}/>
					</div>
					<div className="w-3/12">
						<Button type="secondary" text="Annuler" onClick={handleClose}/>
					</div>
				</div>
			</div>
		</div>
	</ModalReact>
}

export default ValidInvoiceCard
